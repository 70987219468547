import * as React from 'react'
import {Header} from "../../components/header/Header";
import {useEffect, useState} from "react";
import {PaymentSummary} from "../../domain/payments/PaymentSummary";
import {SummaryPaymentsByType} from "../../domain/payments/SummaryPaymentsByType";
import PaymentService from "../../service/payments/PaymentService";
import "./main.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {format} from "date-fns";
import Currency from "../../domain/core/Currency";
import {AchFilesPayments} from "../ach/files/achFilesPayments";
import test from "node:test";
export const Main = (): JSX.Element => {

    useEffect(() => {
        getTopPaymentsData();
    }, []);

    const [topPayors, setTopPayors] = useState<PaymentSummary[]>([]);
    const [topPayees, setTopPayees] = useState<PaymentSummary[]>([]);
    const getTopPaymentsData = () =>{
        var summaryRequest = new SummaryPaymentsByType();
        summaryRequest.paymentType="payor";
        summaryRequest.startDate=new Date(new Date().getFullYear(), 0, 1).toISOString();
        summaryRequest.endDate=new Date().toISOString();
        PaymentService.topPayments(summaryRequest).then((paymentData =>{
            if(paymentData.payments != null) {
                setTopPayors(paymentData.payments)
             }
            }
        ));
        summaryRequest.paymentType="payee";
        PaymentService.topPayments(summaryRequest).then((paymentData =>{
            if(paymentData.payments != null) {
                setTopPayees(paymentData.payments)
            }
            }
        ));
    }
    return (<div>
        <Header isLoggedIn={true}></Header>
        <div className="container">
            <h1>YTD Top 10 Payors</h1>
            <div className="_toppayments_list_header _toppayments_list_header_color">
                <div>Name</div>
                <div>Type</div>
                <div style={{textAlign: "right"}}>Amount</div>
            </div>
            {topPayors.map((topPayor, index) => {
                return (
                    <div>
                        <div key={index} className="_toppayments_list_header">
                            <div>{topPayor.name}</div>
                            <div>{topPayor.type}</div>
                            <div style={{textAlign: "right"}}><Currency amount={topPayor.amount}/></div>
                        </div>
                    </div>
                );
            })}
            <h1>YTD Top 10 Payees</h1>
            <div className="_toppayments_list_header _toppayments_list_header_color">
                <div>Name</div>
                <div>Type</div>
                <div style={{textAlign: "right"}}>Amount</div>
            </div>
            {topPayees.map((topPayee, index) => {
                return (
                    <div>
                        <div key={index} className="_toppayments_list_header">
                            <div>{topPayee.name}</div>
                            <div>{topPayee.type}</div>
                            <div style={{textAlign: "right"}}><Currency amount={topPayee.amount}/></div>
                        </div>
                    </div>
                );
            })}
        </div>
    </div>);
}
